import React from 'react';
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby';
import './src/styles/styles.scss';

export default function WrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  return <>{element}</>;
}
